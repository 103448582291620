import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { inject, isDevMode } from '@angular/core';
import type {
  ICompetitionScoring,
  ICompetitionUser,
  ICompetitionUserPerClass,
  ICompetitionUserScoring,
} from '@archery-scoring/models/competition.model';
import { CompetitionService } from './competition.service';
import { withCallState } from '@owain/store-features/features/call-state/call-state.feature';
import { withDevtools } from '@owain/store-features/features/debugging/devtools';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { first, firstValueFrom, pipe, switchMap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import type { IMiscHttpError } from '@archery-scoring/models/misc.model';
import { TranslocoService } from '@jsverse/transloco';

export const initialState: ICompetitionScoring = {
  id: -1,
  users: [],
  classes: [],
  userperclass: [],
};

const totalScore = (scores: ICompetitionUserScoring[]) => {
  return scores
    .map((target: ICompetitionUserScoring) => target.totalscore)
    .reduce((a: number, b: number) => Math.max(a, b), 0);
};

const totalArrows = (scores: ICompetitionUserScoring[]) => {
  return scores
    .map((target: ICompetitionUserScoring) => target.arrowcount)
    .reduce((a: number, b: number) => Math.max(a, b), 0);
};

const totalFirstSpecial = (scores: ICompetitionUserScoring[]) => {
  return scores.filter((target: ICompetitionUserScoring) => target.firstspecial).length;
};

const totalFirstSpot = (scores: ICompetitionUserScoring[]) => {
  return scores.filter((target: ICompetitionUserScoring) => target.firstspot).length;
};

const totalFirstKill = (scores: ICompetitionUserScoring[]) => {
  return scores.filter((target: ICompetitionUserScoring) => target.firstkill).length;
};

const totalFirstVital = (scores: ICompetitionUserScoring[]) => {
  return scores.filter((target: ICompetitionUserScoring) => target.firstvital).length;
};

export const AdminScoringStore = signalStore(
  { providedIn: 'root' },
  withDevtools('Archery Scoring', 'Admin Competition Scoring'),
  withState(initialState),
  withCallState(),
  withMethods(store => {
    const competitionsService: CompetitionService = inject(CompetitionService);
    const translocoService: TranslocoService = inject(TranslocoService);

    return {
      reset(): void {
        patchState(store, { callState: 'loading' });

        patchState(store, {
          id: -1,
          users: [],
          classes: [],
        });

        patchState(store, { callState: 'loaded' });
      },

      getCompetitionId(): number {
        return store.id();
      },

      setCompetitionId(competitionId: number): void {
        patchState(store, { callState: 'loading' });

        patchState(store, {
          id: competitionId,
          users: [],
          classes: [],
        });

        patchState(store, { callState: 'loaded' });
      },

      getCompetitionScores: rxMethod<{ gender: boolean; agebrackets: boolean | null | undefined }>(
        pipe(
          switchMap((filters: { gender: boolean; agebrackets: boolean | null | undefined }) => {
            patchState(store, { callState: 'loading' });

            return competitionsService.getScores(store.id()).pipe(
              tapResponse({
                next: async (users: ICompetitionUser[]): Promise<void> => {
                  const classes: string[] = [];
                  let usersperclass: ICompetitionUserPerClass[] = [];

                  for (const user of users) {
                    let className = null;

                    if (user.bowclass) {
                      className = user.bowclass.replaceAll('-', '—');
                    }

                    if (filters.gender && user.gender) {
                      const gender = await firstValueFrom(translocoService.selectTranslate(user.gender, {}, { scope: 'admin' }));

                      className =
                        `${className} — ${gender}`.replaceAll(
                          '-',
                          '—'
                        );
                    }

                    if (filters.agebrackets && user.age) {
                      className = `${className} — ${user.age}`;
                    }

                    if (className) {
                      if (!classes.includes(className)) {
                        classes.push(className);
                        usersperclass.push({
                          class: className,
                          users: [],
                        });
                      }

                      if (usersperclass.map((upc: ICompetitionUserPerClass) => upc.class).includes(className)) {
                        const filterMatch: ICompetitionUserPerClass | undefined = usersperclass.find(
                          (upc: ICompetitionUserPerClass) => upc.class === className
                        );
                        const filterNotMatch = usersperclass.filter(
                          (upc: ICompetitionUserPerClass) => upc.class !== className
                        );

                        if (filterMatch) {
                          usersperclass = [
                            ...filterNotMatch,
                            {
                              class: filterMatch.class,
                              users: [...filterMatch.users, user],
                            },
                          ];
                        }
                      }
                    }
                  }

                  usersperclass.sort((a: ICompetitionUserPerClass, b: ICompetitionUserPerClass) =>
                    a.class.localeCompare(b.class)
                  );

                  usersperclass.forEach((_: ICompetitionUserPerClass, index: number) => {
                    usersperclass[index].users.sort(
                      (a: ICompetitionUser, b: ICompetitionUser) =>
                        totalScore(b.scoring!) - totalScore(a.scoring!) ||
                        totalArrows(a.scoring!) - totalArrows(b.scoring!) ||
                        totalFirstSpecial(b.scoring!) - totalFirstSpecial(a.scoring!) ||
                        totalFirstSpot(b.scoring!) - totalFirstSpot(a.scoring!) ||
                        totalFirstKill(b.scoring!) - totalFirstKill(a.scoring!) ||
                        totalFirstVital(b.scoring!) - totalFirstVital(a.scoring!)
                    );
                  });

                  patchState(store, {
                    id: store.id(),
                    users: users,
                    classes: classes.sort((a: string, b: string) => a.localeCompare(b)),
                    userperclass: usersperclass,
                  });
                },
                error: (err: IMiscHttpError) => {
                  if (isDevMode()) {
                    console.error('[scores]', err);
                  }
                },
                finalize: () => {
                  patchState(store, { callState: 'loaded' });
                },
              })
            );
          })
        )
      ),
    };
  })
);
